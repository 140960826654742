import React from "react";
import Layout from "../Layout/Layout";
import Blob from "../Blob/Blob";
import styles from "./SubmissionWrapper.module.scss";
import SEO from "../seo";

const SubmissionWrapper = ({ children }) => {
  return (
    <Layout className={styles.register}>
      <SEO title="Page two" />
      <div className={styles.innerContent}>
        <Blob position="bottom left" />
        <Blob position="right top" />
        {children}
      </div>
    </Layout>
  );
};

export default SubmissionWrapper;
