import React from "react";
import { Headline1, Body1 } from "../components/Typography/Typography";
import SubmissionWrapper from "../components/SubmissionWrapper/SubmissionWrapper";
import { Link } from "gatsby";

const Gratulerer = () => {
  return (
    <SubmissionWrapper>
      <Link
        style={{
          position: "absolute",
          top: "5%",
          left: "5%",
          cursor: "pointer",
          textDecoration: "none",
          color: "var(--color-gray-700)",
        }}
        to="/"
      >
        Tilbake
      </Link>
      <div>
        <Headline1 align="center">Velkommen til Lettdugnad!🎉</Headline1>
        <Body1>
          Vi vil vi ta kontakt for å få dere i gang med digitale dugnader!
        </Body1>
      </div>
    </SubmissionWrapper>
  );
};

export default Gratulerer;
